import Vue from "vue";
import { LiffListConfigInterface } from "@/components/list/types/list";
import dispatchTab from "@/modules/base/config/dispatchTab.ts";

class listConfig {
  protected vm?: Vue;
  protected listKey?: string;

  get(vm: Vue, listKey: string): LiffListConfigInterface {
    this.vm = vm;
    this.listKey = listKey;

    return {
      searchFilterOrder: 2,
      targetKey: 'order_no',
      pageTitle: "module.dispatch",
      sort: [{ label: "data.created_at", key: "created_at" }],
      liffFilter: {
        delivery_status: {
          type: "tab",
          options: () => {
            return dispatchTab;
          },
        }
      },
      listItem: () =>
        import("@/modules/provider/views/delivery/deliveryCardItem.vue"),
      useRow: false,
      batch: {
        batch_apply: {
          label: "批次派遣",
          column: "selected",
          value: "apply",
        },
      },
      listSelectLimitType: {
        delivery_man_id: "string",
        arrival_at: "string",
      },
      selectedData: (row) => row,
    };
  }
}

export default new listConfig();
