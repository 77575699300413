<template>
  <div>
    <v-container pt-2 pb-1>
      <page-title :title="pageTitle" />
    </v-container>

    <liff-list :listKey="listKey" :unprocessedKeys="['delivery_man_id']" :key="new Date().getTime()"></liff-list>

  </div>
</template>

<script lang="babel" type="text/balel">
import _cloneDeep from 'lodash/cloneDeep'
import listMixin from "@/components/list/mixins/listMixin.js"
import listConfig from "./deliveryList"

export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'liff-provider-delivery-list',
  }),
  computed: {
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`]
    },
  },
  watch: {
  },
  mounted () {
    this.$root.gaLogEvent('供應商_訂單派遣頁')
  },
  created() {
    this.getData()
  },
  methods: {
    getListConfig() {
      return listConfig
    },
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      // status: "apply,sended"
      params.filter = {status: "sended", ...params.filter}
      let listToolbarShow = false
      if ("delivery_status" in params.filter && params.filter.delivery_status === "pending") {
        listToolbarShow = true
      }
      this.$store.dispatch(`list/${this.listKey}/set`, { key: 'listToolbarShow', value: listToolbarShow})
      return await this.$api.collection.providerApi.getDeliveries(this.providerId, params)
    },
    async getData() {
      const driverList = await this.$api.collection.providerApi.getDrivers(this.providerId)
      this.$store.dispatch(`list/${this.listKey}/set`, {key: 'driverList', value: driverList})
    },
    async batchApi(targets, column, value) {
      if (targets.length > 0) {
        const params = {
          ids: targets
        }
        this.$root.gaLogEvent('供應商_點擊_派遣訂單')
        const res = this.$api.collection.providerApi.batchDeliverStatus(this.providerId, params)
      }
    }
  },
}
</script>


<style lang="sass" type="text/sass" scoped></style>